if (document.getElementsByClassName("modal")[0]) {
  var modalEl = document.getElementsByClassName("modal")[0];
  var closeModalEl = document.getElementsByClassName("modal__close")[0];

  var setCookie = function (name, value, expiryDays) {
    var d = new Date();
    d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
    return getCookie(name);
  };

  var getCookie = function (name) {
    var cookieName = name + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return false;
  };

  var initialHistory = (sessionStorage.getItem("initialHistory") === null) ? window.history.length : +sessionStorage.getItem("initialHistory");
  var currentHistory = window.history.length;
  var dateShown;

  //Setting initialHistory in sessionStorage
  if (sessionStorage.getItem("initialHistory") === null) {
    sessionStorage.setItem("initialHistory", initialHistory);
  }

  //Showing the modal and setting modalShow to 'true'
  if (!document.getElementById("elc-cookie-consent").classList.contains("hidden")) {
    [document.getElementById("elc-accept-all-link"), document.getElementById("elc-accept-link")].forEach(item => {
      item.addEventListener('click', event => {
        setTimeout(function () {
          modalEl.classList.remove("hidden");
        }, 1000);
        setCookie("modalShown", "true", 5);
      })
    })


  } else if (!getCookie("modalShown")) {
    setTimeout(function () {
      modalEl.classList.remove("hidden");
    }, 1000);
    setCookie("modalShown", "true", 30);
  }

  // Close modal
  if (modalEl) {
    window.addEventListener("click", function (e) {
      if (e.target.classList.contains("modal") || e.target.classList.contains("modal__close") || e.target.classList.contains("modal__close--button")) {
        modalEl.classList.add("hidden");
      }
    });
  }
}